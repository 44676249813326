var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar__container",style:({
    'background-color': _vm.color,
    color: _vm.textColor,
  })},[_c('div',{staticClass:"menu_item_tow"},[_c('div',{staticClass:"navbar__slick"},_vm._l((_vm.slickTextClones),function(index){return _c('div',{key:index,class:("navbar__slick__item  navbar__slick__item-" + index)},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.slickText)}})])}),0)]),(_vm.categories.length > 0)?_c('div',{staticClass:"menu_item_one"},[_c('div',{staticClass:"navbar__menu",style:({ 'background-color': _vm.color })},[_c('router-link',{attrs:{"to":_vm.categories[0].url}},[_c('div',{staticClass:"navbar__menu__item",style:({
            color: _vm.textColor,
            'border-color': _vm.textColor,
            'border-style': _vm.borderStyle,
          })},[_vm._v(" "+_vm._s(_vm.categories[0].title)+" ")])]),_c('router-link',{attrs:{"to":_vm.categories[1].url}},[_c('div',{staticClass:"navbar__menu__item navbar__menu__item-middle",style:({
            color: _vm.textColor,
            'border-color': _vm.textColor,
            'border-style': _vm.borderStyle,
          })},[_vm._v(" "+_vm._s(_vm.categories[1].title)+" ")])])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }