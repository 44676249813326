<template>
  <div class="contact__container">
    <div class="left__container">
      <div class="email__contact">
        <div class="email__sender">
          <div class="email__first">Look at the cube</div>
          <div class="email__second">or</div>
          <div class="email__third">send me a message</div>
          <div class="email__input">
            <div style="display: flex; justify-content: center">
              <div class="email__from__input">
                <label for="email_from">From</label>
                <input
                  type="text"
                  name="email_from"
                  v-model="sender"
                  :style="{
                    border:
                      this.error && this.sender === ''
                        ? 'solid 2px red'
                        : ' dashed 2px black',
                  }"
                />
              </div>
              <div class="email__subject__input">
                <label for="email_subject">Subject</label>
                <input
                  type="text"
                  name="email_subject"
                  v-model="subject"
                  :style="{
                    border:
                      this.error && this.subject === ''
                        ? 'solid 2px red'
                        : ' dashed 2px black',
                  }"
                />
              </div>
            </div>
            <div class="email__textarea">
              <label for="email_message">Message</label>
              <textarea
                rows="8"
                name="email_message"
                v-model="message"
                :style="{
                  border:
                    this.error && this.message === ''
                      ? 'solid 2px red'
                      : ' dashed 2px black',
                }"
              >
              </textarea>
            </div>
          </div>
          <div
            :style="{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }"
          >
            <div
              :style="{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                color: 'red',
              }"
            >
              <div v-if="notSend" :style="{ fontSize: '10px', color: 'red' }">
                Something went wrong
              </div>
            </div>
            <div class="email__button__wrapper">
              <button @click="sendMail" class="email__button">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right__container">
      <div class="cube__container">
        <div class="cube">
          <div class="face front"></div>
          <div class="face back"></div>
          <div class="face right"></div>
          <div class="face left"></div>
          <div class="face top"></div>
          <div class="face bottom"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";

export default {
  name: "Contact",
  data() {
    return {
      message: "",
      subject: "",
      sender: "",
      error: false,
      notSend: false,
    };
  },
  methods: {
    sendMail() {
      if (this.message === "" || this.subject === "" || this.sender === "") {
        this.error = true;
      } else {
        axios
          .post("/emailSend.php", {
            message: this.message,
            subject: this.subject,
            sender: this.sender,
          })
          .then((response) => {
            this.message = "";
            this.sender = "";
            this.subject = "";
            this.notSend = false;
            this.error = false;
            console.log(response.data);
          })
          .catch(() => {
            this.notSend = true;
          });
      }
    },
  },
};
</script>
